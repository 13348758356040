import dynamic from 'next/dynamic';
import React, {useState} from "react";

const StarsRating = dynamic(() => import('@/app/components/atomic/StarsRating'), {
    ssr: false,
});

interface Review {
    firstname: string;
    rate: number;
    review: string;
    review_date: string;
}

interface Props {
    review: Review
    displayDate?: boolean
    fixedHeight?: boolean
    className?: string
}

export default function CardReview({review, displayDate = false, fixedHeight = false, className}: Props) {
    const getHsl = (): string => {
        const hue = Math.floor(Math.random() * 360);
        const saturation = Math.floor(Math.random() * 20) + 20;
        const lightness = Math.floor(Math.random() * 30) + 40;

        return `hsl(${hue} ${saturation}% ${lightness}%)`;
    }

    const [color, setColor] = useState<string>(getHsl())


    const formattedDate = (): string => {
        const [date] = review.review_date.split(' ');
        const [year, month, day] = date.split('-');
        const formattedDate = [day, month, year.slice(-2)].join('/');

        return formattedDate;
    };

    return (
        <div className={`rounded-lg p-6 space-y-6 flex flex-col bg-white shadow-sm ${className} ${fixedHeight ? 'h-56' : ''}`}>
            <div className="flex w-full justify-between">
                <div className="flex">
                    <div
                        className="text-3xl rounded-full text-white w-14 h-14 flex items-center justify-center"
                        style={{backgroundColor: color}}
                    >
                        {review.firstname[0]}
                    </div>
                    <div className="ml-4">
                        <span className="text-lg font-semibold">{review.firstname}</span>
                        <StarsRating starsFontSize="text-sm" notation={review.rate} />
                    </div>
                </div>
                {displayDate && (
                    <div
                        className="text-sm text-right"
                    >
                        le {formattedDate()}
                    </div>
                )}
            </div>
            <div className={`text-[14px] ${fixedHeight ? 'overflow-hidden text-ellipsis' : ''}`}>{review.review}</div>
        </div>
    );
};
